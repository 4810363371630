import $ from 'jquery';

export default class ShrinkNav {
  static init() {
    var self = this;

    $(window).scroll(function(){
        if ($(this).scrollTop() > 200) {
           $('body').addClass('shrink-nav');
        } else {
           $('body').removeClass('shrink-nav');
        }
    });

  }
}