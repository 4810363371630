import $ from 'jquery';

export default class Dropdown {
  static init() {
    var self = this;

    $('.find-a-clinic').click(function () {
      if (!$('.pre-header').hasClass('has-no-location')) {
        $('.pre-header').addClass('has-no-location');
      }

      if ($('.input-location').hasClass('collapsed')) {
        $('.input-location').removeClass('collapsed').addClass('open');
      }
    });

    $('.input-location .close-button').click(function () {
      $('.pre-header').removeClass('has-no-location');
      $('.input-location').removeClass('open').addClass('collapsed');
    });

  }
}
