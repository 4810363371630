import $ from 'jquery';
import "../../node_modules/waypoints/lib/noframework.waypoints";

export default class Waypoints {
  static init() {
    var self = this;

    var el1 = document.getElementById('section1');
    if (el1) {
      var waypoint = new Waypoint({
        element: el1,
        handler: function (direction) {
          $('.row.start').addClass('show');
        },
        offset: '75%'
      });
    }

    var el2 = document.getElementById('section2');
    if (el2) {
      var waypoint = new Waypoint({
        element: el2,
        handler: function (direction) {
          $('.row.middle').addClass('show');
        },
        offset: '75%'
      });
    }

    var el3 = document.getElementById('section3');
    if (el3) {
      var waypoint = new Waypoint({
        element: el3,
        handler: function (direction) {
          $('.row.end').addClass('show');
        },
        offset: '75%'
      });
    }



    var el4 = document.getElementById('finger-buttons');
    if (el4) {
      var waypoint = new Waypoint({
        element: el4,
        handler: function (direction) {
          $('#finger-buttons .finger').removeClass('pre-position');
          setTimeout(function() {
            $('#finger-buttons .finger').attr('data-index', -1);
          },1500);
        },
        offset: '50%'
      });
    }

  }
}