import $ from "jquery";
window.$ = $;
import "./main.scss";
import "object-fit-polyfill";
import "slick-carousel";
import Reviews from "./js/Reviews";
import Inputs from "./js/Inputs";
import Menu from "./js/Menu";
import Waypoints from "./js/Waypoints";
import Carousels from "./js/Carousels";
import FAQAccordian from "./js/FAQAccordian";
import Maps from "./js/Maps";
import Modals from "./js/Modals";
import Video from "./js/Video";
import Dropdown from "./js/Dropdown";
import ShrinkNav from "./js/ShrinkNav";


$(document).ready(function () {

  Menu.init();
  Inputs.init();
  Reviews.init();
  Waypoints.init();
  Carousels.init();
  FAQAccordian.init();
  //Maps.init();
  Modals.init();
  Video.init();
  Dropdown.init();
  ShrinkNav.init();

});