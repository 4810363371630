import $ from 'jquery';

export default class Modals {
  static init() {
    var self = this;

    $('[data-modal]').click(function (event) {
      event.preventDefault();
      var modalId = $(this).attr('data-modal');
      $(`#${modalId}`).addClass('show');
      $('html').addClass('modal-show');
    });

    $('.modal-container .close').click(function(event) {
      event.preventDefault();
      $(this).parents('.modal-container').removeClass('show');
      $('html').removeClass('modal-show');
    });

    $('html').delegate('.modal-container.show', 'click', function (e) {
      var container = $(".inner");
      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && $(e.target).has(container).length !== 0) {
        $('.modal-container').removeClass('show');
        $('html').removeClass('modal-show');
      }
    });

  }
}