import $ from 'jquery';

export default class FAQAccordian {
  static init() {
    var self = this;


    $('.faq-accordian .question').toggleClass('closed');
    $('.answer').animate({
      height: "toggle"
    }, 200);

    $('.faq-accordian .section-heading').toggleClass('closed');
    $('.faq-accordian .section-body').animate({
      height: "toggle"
    }, 200);

    $('.faq-accordian .section-heading').click(function() {
      $(this).toggleClass('closed');
      $(this).next().animate({
        height: "toggle"
      }, 200);
    });

    $('.faq-accordian .question').click(function () {
      $(this).toggleClass('closed');
      $(this).next().animate({
        height: "toggle"
      }, 200);
    });

  }
}