import $ from 'jquery';

export default class Menu {
  static init() {
    var self = this;

    if ($('.closer').length < 1) {
      $('html').append('<div class="closer"></div>');
    }

    $('.hamburgler').click(function () {
      $(this).toggleClass("open");
      $('html').toggleClass("open");
    });

    $('.menu .section').click(function () {
      $(this).toggleClass("open");
    });

    $('.closer').click(function () {
      $('.hamburgler').removeClass("open");
      $('html').removeClass("open");
    });


    $('.find-a-clinic').click(function () {
      $('.pre-header').addClass("select-location");
    });

    $('.pre-header .close-button').click(function () {
      $('.pre-header').removeClass("select-location");
    });



  }
}