import $ from 'jquery';
import Common from "../js/Common";

export default class Inputs {
  static init() {
    var self = this;

    $('.video-container-with-play').on('click', function () {
      var video = $(this).find('video')[0];
      if ($(this).hasClass('playing')) {
        video.pause();
      } else {
        video.play();
      }
      $(this).toggleClass('playing');
    });

    if (Common.detectIE()) {
      this.resizeVideo();
      $(window).resize(this.resizeVideo);
    } else {
      $('.half-circle-mask-group .section.two').addClass('modern-browser');
    }
  }

  static resizeVideo() {
    var $halfCircleGroup = $('.half-circle-mask-group');
    var $video = $halfCircleGroup.find('video');
    var video = $video && $video[0];

    if (!video) {
      return;
    }

    var $sectionOne = $halfCircleGroup.find('.section.one');
    var $sectionTwo = $halfCircleGroup.find('.section.two');
    var screenWidth = window.innerWidth;


    var w = video.videoWidth;
    var h = video.videoHeight;
    var ratio = w/h;

    var containerW = screenWidth * 0.55;
    if (screenWidth < 760) {
      containerW = screenWidth;
    }
    var containerH = $sectionOne.innerHeight();
    var containerRatio = containerW/containerH;

    var finalH = (containerW * h) / w;
    var finalW = containerW;
    if (containerRatio < ratio) {
      finalH = containerH;
      finalW = (containerH * w) / h;
    }

    $video.height(finalH);
    $sectionTwo.height(containerH);

    $video.width(finalW);
    $sectionTwo.width(containerW);
  }
}