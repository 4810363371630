import $ from 'jquery';
import loadGoogleMapsApi from "load-google-maps-api";

const customStyle = [{ "featureType": "administrative", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{ "color": "#444444" }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] }, { "featureType": "landscape.natural.terrain", "elementType": "all", "stylers": [{ "visibility": "on" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi.park", "elementType": "geometry.fill", "stylers": [{ "visibility": "on" }, { "saturation": "-88" }, { "lightness": "46" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }, { "visibility": "on" }] }, { "featureType": "road", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road.highway", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#d4d4d4" }, { "visibility": "on" }] }];


export default class Maps {
  static init() {
    var self = this;

    loadGoogleMapsApi(
      { key: "AIzaSyAPR0r7_3Bljxta9d6QvIXqeuUsl0heM9Y" }
    ).then(function (googleMaps) {

      $('.render-google-map').each(function() {

        console.log(window.location.href);
        var location = window.location.href;
        var markerUrl = 'assets/marker.png';
        if (location.indexOf("/docs") > -1) {
          markerUrl = '../assets/marker.png';
        }

        var ar = $(this).data('locations') || [];
        var bounds = new googleMaps.LatLngBounds();
        var initialLat = 30.270261;
        var initialLng = -97.747936;
        
        if (ar.length <= 1) {
          initialLat = Number(ar[0].lat);
          initialLng = Number(ar[0].lng);
        }

        var thisMap = new googleMaps.Map($(this)[0], {
          center: {
            lat: initialLat,
            lng: initialLng
          },
          zoom: 12,
          disableDefaultUI: true,
          styles: customStyle,
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false
        });

        $(this).append($("<div class=\"marker-modal\"><div class=\"name\"></div><div class=\"address\"></div><div class=\"link\"><a href=\"\">Use this clinic</a></div></div>"));

        for (var i=0; i<ar.length; i++) {
          var markerImage = new googleMaps.MarkerImage(
            markerUrl,
            null, //new google.maps.Size(38,52),
            null, //new google.maps.Point(0,0),
            null, //new google.maps.Point(19,52),
            new googleMaps.Size(50, 73)
          );
          var myLatLng = new googleMaps.LatLng(Number(ar[i].lat), Number(ar[i].lng));
          bounds.extend(myLatLng);
          var marker = new googleMaps.Marker({
            position: myLatLng,
            map: thisMap,
            icon: markerImage,
            data: ar[i]
          });

          var localSelf = this;

          googleMaps.event.addListener(marker, 'click', function () {
            $(localSelf).find('.marker-modal').removeClass('show');
            thisMap.setCenter(myLatLng);
            $(localSelf).find('.marker-modal .name').text(ar[i].name);
            $(localSelf).find('.marker-modal .address').text(ar[i].address);
            $(localSelf).find('.marker-modal .link a').attr('href', ar[i].link);
            setTimeout(function () {
              $(localSelf).find('.marker-modal').addClass('show');
            }, 100);
          });
        }

        googleMaps.event.addListener(thisMap, 'dragstart', function () {
          $(localSelf).find('.marker-modal').removeClass('show');
        });

        if (ar.length > 1) {
          thisMap.fitBounds(bounds);
        }

      });



      
      console.log('loaded maps');
    }).catch(function (error) {
      console.error(error)
    })
  }
}