import $ from 'jquery';
import "../../node_modules/waypoints/lib/noframework.waypoints";
var cleanData = [
  { "index": 0, "value": 0 },
  { "index": 5, "value": 0 },
  { "index": 4, "value": 0 },
  { "index": 3, "value": 0 },
  { "index": 2, "value": 0 },
  { "index": 1, "value": 0 }
];
var dataReset = [
  { "index": 0, "value": 0 },
  { "index": 1, "value": 0 },
  { "index": 2, "value": 0 },
  { "index": 3, "value": 0 },
  { "index": 4, "value": 0 },
  { "index": 5, "value": 0 }
];

export default class Reviews {
  static init() {
    var self = this;

    $('.arrow.slick-prev').click(function () {
      $('.cycle').slick('slickPrev');
    });

    $('.arrow.slick-next').click(function () {
      $('.cycle').slick('slickNext');
    });

    this.initStars();

    $('.cycle').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
      arrows: false,
      adaptiveHeight: true,
      infinite: true
    });

    var reviewsElement = document.getElementById('reviews');
    if (reviewsElement) {
      var localSelf = this;
      var waypoint = new Waypoint({
        element: document.getElementById('reviews'),
        handler: function(direction) {
          var rating = $('.overall-rating-value').html();
          rating = Number(rating);
          localSelf.setStarData(rating);
        }
      });
    }

  }

  static initStars() {
    this.stars = $('.stars');
    for (var i=1; i<6; i++) {
      var star = $(`<div class="star" data-index="${i}" />`);
      var mask = $('<span class="mask" />');
      var fill = $('<span class="fill" />');
      star.append(mask);
      star.append(fill);
      this.stars.append(star);
    }
  }



  static setStarData(rating) {
    var maxWidth = window.innerWidth <= 760 ? 24 : 44;
    var newData = dataReset.slice();
    for (var j = 0; j < newData.length; j++) {
      newData[j].value = 0;
    }
    rating = Number(rating);
    if (typeof rating === 'number' && rating >= 0 && rating <= 5) {
      var floor = Math.floor(rating);
      var fraction = rating - floor;
      if (floor === 0) {
        newData[1].value = maxWidth * fraction;
      } else {
        for (var i=1; i<floor+1; i++) {
          newData[i].value = maxWidth;
        }
        if (floor + 1 <= 5) {
          newData[floor +1].value = maxWidth * fraction;
        }
      }
      this.setStarDataByArray(newData);
    }
  }

  static setStarDataByArray(data) {
    this.stars.addClass('show');
    this.stars.find(`.star[data-index="${data[1].index}"] .fill`).width(data[1].value);
    this.stars.find(`.star[data-index="${data[2].index}"] .fill`).width(data[2].value);
    this.stars.find(`.star[data-index="${data[3].index}"] .fill`).width(data[3].value);
    this.stars.find(`.star[data-index="${data[4].index}"] .fill`).width(data[4].value);
    this.stars.find(`.star[data-index="${data[5].index}"] .fill`).width(data[5].value);
  }
}