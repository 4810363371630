import $ from 'jquery';

export default class Carousels {
  static init() {
    var self = this;

    $('.arrow.carousel-prev').click(function () {
      var instance = $(this).data('instance');
      $('.carousel[data-instance="' + instance + '"').slick('slickPrev');
    });

    $('.arrow.carousel-next').click(function () {
      var instance = $(this).data('instance');
      $('.carousel[data-instance="' + instance + '"').slick('slickNext');
    });

    $('.carousel').on('init', function (event, slick, currentSlide, nextSlide) {
      self.setPosition(slick);
    });

    $('.carousel').on('afterChange', function (event, slick, currentSlide, nextSlide) {
      self.setPosition(slick);
    });

    $('.carousel').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
      arrows: false,
      adaptiveHeight: false,
      infinite: true
    });

  }

  static setPosition(slick) {
    var totalSlides = slick.$slides.length;
    var instance = slick.$slider.data('instance');
    var currentSlideNumber = slick.currentSlide + 1;
    $('.position[data-instance="' + instance + '"').html(`${currentSlideNumber}/${totalSlides}`);
  }
}