import $ from 'jquery';

export default class Inputs {
  static init() {
    var self = this;

    ;(function () {      
      var input = document.querySelectorAll('zip-code');

      if ($(input).val() !== '') {
        $(input).parents('.input').addClass('input-filled');
        console.log('filled');
      } else {
        $(input).parents('.input').removeClass('input-filled');
        console.log('empty');
      }

    })();

    $('input').on('focus', function () {
      $(this).parents('.input').addClass('input-filled');
    });
    $('input').on('blur', function () {
      var val = $(this).val();
      if (!val || val === '') {
        $(this).parents('.input').removeClass('input-filled');
      }
    });

  }
}